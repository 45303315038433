<template>
  <div class="f-container">
    <div v-for="(item) in handleList" :key="item.id" class="list">
      <img :src="item.image" class="image" alt="" />
      <div class="text">{{ item.text }}</div>
      <div class="btn1" @click="login(item.button1.id)">{{ item.button1.text }}</div>
      <div class="btn2" @click="login(item.button2.id)">{{ item.button2.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      handleList: [
        {
          image: require("../../../assets/images/issue/man-ico.png"),
          text: "即刻注册会员",
          button1: {
            id:1,
            text: "创建我的账户"
          },
          button2: {
            id:2,
            text: "登录我的账户"
          }
        },
        {
          image: require("../../../assets/images/issue/heart-ico.png"),
          text: "更多会员帮助",
          button1: {
             id:3,
            text: "申请售后"
          },
          button2: {
             id:4,
            text: "联系我们"
          }
        }
      ]
    };
  },
  methods:{
    login(id){
      if(id==1){
        this.$router.push({
          path:'/register'
        })
      }else if(id==2){
        this.$router.push({
             path:'/createLogin.html'
        })
      }else if(id==3||id==4){
         document.getElementById("sapp").scrollTop =
        document.getElementsByClassName("footer")[0].offsetTop -
        document.getElementsByClassName("tab-list")[0].offsetHeight;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.f-container {
  display: flex;
  justify-content: center;
  margin-top: 100px; /* no */
  margin-bottom: 200px; /* no */
}
.list {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px; /* no */
  background: #f5f5f5;
  border: 1px solid #666666; /* no */
  padding: 32px 0; /* no */
  width: 500px; /* no */
  .image {
    width: 60px; /* no */
    height: 60px; /* no */
    margin-bottom: 35px; /* no */
  }
  .text {
    font-size: 15px; /* no */
    color: #666666; /* no */
    margin-bottom: 35px; /* no */
  }
  .btn1,
  .btn2 {
    width: 274px; /* no */
    height: 36px; /* no */
    margin-bottom: 20px; /* no */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px; /* no */
    color: #666666; /* no */
    border: 1px solid #666666; /* no */
    cursor: pointer;
  }
}

@media only screen and (max-width: 640px) {
  .f-container {
    flex-flow: column;
    align-items: center;
    padding: 0 20px; /* no */
    margin-bottom: 20px; /* no */
    margin-top: 60px; /* no */
  }
  .list {
    margin-right: 0px;
    margin-bottom: 20px; /* no */
    width: 92%;
    .btn1,
    .btn2 {
      width: 60%;
    }
  }
}
@media only screen and (min-width: 640px) and (max-width: 960px) {
  .f-container {
    flex-flow: column;
    align-items: center;
    padding: 0 20px; /* no */
    margin-bottom: 20px; /* no */
  }
  .list {
    margin-right: 0px;
    margin-bottom: 20px; /* no */
    width: 80%;
    .btn1,
    .btn2 {
      width: 60%;
    }
  }
}
@media only screen and (min-width: 960px) and (max-width: 1200px) {
}
@media only screen and (min-width: 1200px) {
}
</style>
