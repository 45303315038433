<!--
 * @Author: your name
 * @Date: 2020-06-11 12:54:13
 * @LastEditTime: 2020-06-19 11:50:15
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\views\issue\components\Header.vue
--> 
<template>
  <div class="h-container">
    <p class="title">常见问题</p>
    <p class="info">
      如果您在订购时需要帮助,或者您想了解伊莉丝的定制、服务和配送流程,或许可以在常见问题中找到答案
    </p>
    <div class="banner"></div>
    <p class="text">伊莉丝钻石</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
p {
  text-align: center;
  color: #000000;
  box-sizing: border-box;
}
.h-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 26px; /* no */
  font-weight: 200;
  margin-bottom: 20px; /* no */
  margin-top: 90px; /* no */
}
.info {
  width: 604px; /* no */
  font-size: 18px; /* no */
  line-height: 24px; /* no */
  margin-bottom: 80px; /* no */
}

.banner {
  width: 1274px; /* no */
  background-image: url("../../../assets/images/issue/banner.png");
  height: auto;
  background-repeat: no-repeat;
  &:before {
    content: "";
    display: block;
    padding-top: 30%;
  }
}

.text {
  font-size: 18px; /* no */
  color: #000000;
  margin-bottom: 75px; /* no */
  margin-top: 20px; /* no */
}

@media only screen and (max-width: 640px) {
  .banner {
    width: 100%;
    background-size: 100% auto;
  }
  .title,
  .info {
    width: 100%;
    padding: 0 20px; /* no */
  }
  .title {
    font-size: 20px; /* no */
  }
  .info {
    font-size: 16px; /* no */
  }
}
@media only screen and (min-width: 640px) and (max-width: 960px) {
  .banner {
    width: 100%;
    background-size: 100% auto;
  }
  .title,
  .info {
    width: 100%;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .banner {
    width: 900px; /* no */
    background-size: 100% auto;
  }
  .title,
  .info {
    width: 100%;
  }
}
@media only screen and (min-width: 1200px) {
}
</style>
