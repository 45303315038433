<template>
  <div class="c-container">
    <div class="issue-box">
      <div class="issue">
        <div v-for="(item, index) in issueList" :key="index" class="issue-list">
          <div class="q">
            <p>Q:</p>
            {{ item.question }}
          </div>
          <div class="a">
            <p>A:</p>
            {{ item.answer }}
          </div>
        </div>
      </div>
      <div class="unfold" @click="clickUnfold">
        {{ unfoldClassState ? "收起" : "展开更多" }}
        <img
          v-if="!unfoldClassState"
          src="@/assets/images/issue/展开更多.png"
          alt=""
        />
        <img v-else src="@/assets/images/issue/收起.png" alt="" />
      </div>
    </div>
    <div class="qr-right">
      <div class="qr-code">
        <p class="text">如需了解更详细的信息,</p>
        <p class="text">请致电010-56984858</p>
        <p class="text">您还可以通过微信联络伊莉丝的销售顾问</p>
        <div class="qr-image"></div>
      </div>
      <div class="handle-list">
        <div
          v-for="(item, index) in handleList"
          :key="index"
          class="list"
          @click="clickList(item.path)"
        >
          <div class="l">
            <img src="@/assets/images/issue/success-d.png" alt="" />
            <p>{{ item.text }}</p>
          </div>
          <img src="@/assets/images/issue/arrow.png" class="r" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_ISSUE } from "@/service/issue";
export default {
  data() {
    return {
      issueList: [],
      initIssueList: [],
      unfoldClassState: false,
      handleList: [
        {
          text: "服务中心",
          path: "#footer"
        },
        {
          text: "产品展示",
          path: "/production.html"
        },
        {
          text: "浏览系列",
          path:'/serieslist'
        },
        {
          text: "权威认证",
          path: "/authority.html"
        },
        {
          text: "裸钻定制",
          path:'/diamondorder'
        }
      ]
    };
  },

  created() {
    this.getIssue();
  },

  methods: {
    /**
     * @title 请求问题数据
     */
    getIssue() {
      GET_ISSUE({ uid: 1 }).then(res => {
        const issueList = res.attachment.datas;
        this.initIssueList = issueList;
        this.issueList = issueList.slice(0, 4);
      });
    },

    /**
     * @title 展开
     */
    clickUnfold() {
      this.unfoldClassState = !this.unfoldClassState;
      this.unfoldClassState
        ? (this.issueList = this.initIssueList)
        : (this.issueList = this.initIssueList.slice(0, 4));
    },

    /**
     * @title 跳转路由
     * @param { path } string
     */
    clickList(path) {
      this.$router.push( path );
      if(path == '#footer'){
         document.getElementById("sapp").scrollTop = 999999999
      }
       
    }
  }
};
</script>

<style lang="less" scoped>
.c-container {
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 40px; /* no */
  width: fit-content;
  margin: 0 auto;
  border-top: 1px solid #cfcfcf; /* no */
}
.issue {
  overflow: hidden;
}
.unfold {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px; /* no */
  margin-top: 60px; /* no */
  color: #999999;
  cursor: pointer;
  img {
    width: 36px; /* no */
    margin-left: 16px; /* no */
  }
}
// 左边
.issue-list {
  max-width: 660px; /* no */
  padding: 40px 0; /* no */
  border-bottom: 1px solid #cfcfcf; /* no */
  padding-left: 16px; /* no */
  .q,
  .a {
    color: #000000;
    font-size: 18px; /* no */
    display: flex;
    p {
      margin-right: 8px; /* no */
    }
  }
  .q {
    margin-bottom: 14px; /* no */
  }
  .a {
    line-height: 26px; /* no */
  }
}
// 右边
.qr-right {
  width: 400px; /* no */
  margin-left: 50px; /* no */
  // 上边
  .qr-code {
    background: #999999;
    padding: 72px 0 50px 0; /* no */
    .text {
      color: #ffffff;
      font-size: 18px; /* no */
      margin-bottom: 15px; /* no */
      text-align: center;
      &:nth-child(1) {
        font-size: 25px; /* no */
      }
    }
    .qr-image {
      width: 174px; /* no */
      height: 174px; /* no */
      margin: 0 auto;
      margin-top: 36px; /* no */
      background-image: url("../../../assets/images/issue/qr.png");
      background-size: cover;
    }
  }
  // 下边
  .handle-list {
    background: #000000;
    padding: 30px 54px 180px 58px; /* no */
    box-sizing: border-box;
    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 22px; /* no */
      cursor: pointer;
      .l {
        display: flex;
        align-items: center;
        img {
          width: 14px; /* no */
          height: 14px; /* no */
          margin-right: 9px; /* no */
        }
        p {
          font-size: 14px; /* no */
          color: #ffffff;
        }
      }
      .r {
        width: 8px; /* no */
        height: 16px; /* no */
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .c-container {
    flex-flow: column;
    align-items: center;
    padding: 0 60px;
  }
  .unfold {
    font-size: 14px; /* no */
    margin-top: 20px; /* no */
    img {
      width: 20px; /* no */
    }
  }
  // 左边
  .issue-list {
    .q,
    .a {
      font-size: 14px; /* no */
    }
  }
  .qr-right {
    width: 100%; /* no */
    margin-left: 0px;
    margin-top: 60px; /* no */
    // 上边
    .qr-code {
      background: #999999;
      padding: 72px 0 50px 0; /* no */
      .text {
        color: #ffffff;
        font-size: 14px; /* no */
        margin-bottom: 15px; /* no */
        text-align: center;
        &:nth-child(1) {
          font-size: 18px; /* no */
        }
      }
      .qr-image {
        width: 174px; /* no */
        height: 174px; /* no */
        margin: 0 auto;
        background-image: url("../../../assets/images/issue/qr.png");
        background-size: cover;
      }
    }
  }
}

@media only screen and (min-width: 640px) and (max-width: 960px) {
  .c-container {
    flex-flow: column;
    align-items: center;
    padding: 0 120px;
  }
  .qr-right {
    width: 100%; /* no */
    margin-left: 0px;
    margin-top: 60px; /* no */
  }
}

@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .qr-right {
    width: 350px; /* no */
  }
}

@media only screen and (min-width: 1200px) {
}
</style>
