<template>
  <div class="issue-container">
    <header-container />
    <content-container />
    <footer-container />
  </div>
</template>

<script>
import HeaderContainer from "./components/Header";
import ContentContainer from "./components/Content";
import FooterContainer from "./components/Footer";
export default {
  name: "Issue",

  components: {
    HeaderContainer,
    ContentContainer,
    FooterContainer
  }
};
</script>

<style lang="scss" scoped></style>
